<template>
  <div class="d-block" v-if="modalShow">
    <b-modal
      v-model="modalShow"
      class="modalBranch"
      ref="modalBranch"
      hide-header
      hide-footer
      centered
      body-class="p-0"
    >
      <div class="m-3">
        <div class="modal-confirm-custom text-center title f-xl">
          เลือกสาขาที่ต้องการรับสินค้า
        </div>
        <b-row>
          <b-col>
            <b-form-select
              v-model="selected"
              :options="branch"
              @change="selectBranch"
              value-field="id"
              text-field="name"
            >
              <template #first>
                <b-form-select-option :value="null" disabled
                  >--- เลือกสาขาที่ต้องการรับสินค้า --</b-form-select-option
                >
              </template>
            </b-form-select>
          </b-col>
        </b-row>
      </div>
      <div class="border-top p-3 text-center">
        <b-button
          @click="confirm"
          :disabled="isDisable"
          class="w-100 btn-confirm"
          :style="`background-color: ${$store.state.theme.themePrimaryColor};`"
          >ยืนยัน</b-button
        >
        <b-button
          v-if="$store.state.theme.line_layout == 'Compact'"
          block
          class="mt-2"
          @click="(modalShow = false), (selected = null)"
          variant="outline-primary"
        >
          ยกเลิก
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    detail: {
      required: true,
      type: Object
    },
    branch: {
      required: true,
      type: Array
    },
    redeemQty: { required: false },
    randomReward: { required: false, default: false }
  },
  data() {
    return {
      modalShow: false,
      selected: null,
      body: {
        branch_id: 0,
        redeem_id: 0,
        redeem_type_id: 0,
        product_id: 0,
        redeem_count: 1
      },
      isDisable: true
    };
  },
  methods: {
    show() {
      this.isDisable = true;
      this.modalShow = true;
    },
    close(val) {
      if (val === 1) {
        this.$emit("success", this.msg);
      } else {
        this.$emit("error", this.msg);
      }
      this.modalShow = false;
    },
    selectBranch(evt) {
      if (evt) this.isDisable = false;
      this.$emit("onBranchChange", evt);
    },
    confirm() {
      this.$emit("showPointUsedModal");
    },
    async redeemRandomReward() {
      let payload = {
        id: 0, //case ที่ใช้ id คือ case หน้า admin นะ
        random_reward_order_status: parseInt(
          this.detail.random_reward_order_status
        ),
        branch_id: this.selected ? this.selected : this.branch[0].id,
        home_address: "",
        district: "",
        subdistrict: "",
        pick_up_type: parseInt(this.detail.pick_up_type),
        province: "",
        zip_code: "",
        note: "",
        list_order_id: this.$route.params.id // ถ้า edit เป็น list card ก็ใส่มา ถ้าจะ edit รายเดี่ยวก็ใช้ id เหมือนเดิม (case ที่ใช้ id คือ case หน้า admin นะ)
      };
      this.$emit("showLoading");

      const resp = await this.$axios.post(
        `${process.env.VUE_APP_API}/api/RandomReward/edit_order`,
        payload
      );
      this.$emit("hideLoading");
      if (resp.result) {
        this.msg = "แลกรับของรางวัลสำเร็จ";
        this.close(1);
      } else {
        this.msg = data.message;
        this.close(2);
      }
    },
    async confirmRedeem() {
      if (this.randomReward) {
        return this.redeemRandomReward();
      }
      this.body.redeem_id = this.detail.redeem_id;
      this.body.product_id = this.detail.product_id;
      this.body.redeem_type_id = this.detail.redeem_type_id;
      this.body.redeem_count = this.redeemQty;
      if (this.detail.pick_up_type_id == 3) {
        this.body.branch_id = 0;
      } else {
        this.body.branch_id = this.selected ? this.selected : this.branch[0].id;
      }

      if (this.detail.is_redeem == 0 && this.$route.query.page == "history") {
        this.onConfirm(this.selected);
      } else {
        this.$emit("showLoading");
        this.isDisable = true;
        this.$axios
          .post(
            `${process.env.VUE_APP_API}/api/v1/redeem/redeem_item`,
            this.body
          )
          .then(data => {
            this.$emit("hideLoading");
            this.isLoading = false;
            if (data.result == 1) {
              this.msg = data.detail;
              this.close(1);
            } else {
              this.isDisable = false;
              this.msg = data.detail;
              this.close(2);
            }
          })
          .catch(er => {
            this.$emit("hideLoading");
            this.isLoading = false;
            this.isDisable = false;
            this.msg = "คุณได้แลกสิทธิครบตามที่กำหนดต่อวันแล้ว";
            this.close(2);
          });
      }
    },
    async onConfirm(branchId) {
      try {
        this.$emit("showLoading");
        const res = await this.$axios.post(
          `${process.env.VUE_APP_API}/api/v1/Reward/redeem_coupon/${branchId}`,
          this.detail
        );
        this.$emit("hideLoading");
        if (!res.result) {
          this.$emit("error", res.message);
        } else {
          this.$emit("success", res.message);
        }
      } catch (error) {
        this.$emit("hideLoading");
        console.log(error);
        this.$emit("error", error.message);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.title {
  font-weight: 600;
  padding: 15px 0;
}
::v-deep .btn-secondary:hover {
  color: #fff;
  background-color: #000000;
  border-color: #000000;
}
</style>
