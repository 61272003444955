var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section',{staticClass:"content-detail shadow-sm"},[(_vm.detail.image_url)?_c('div',[_c('img',{staticClass:"w-100",attrs:{"src":_vm.detail.image_url}})]):_c('div',[_c('img',{staticClass:"w-100",attrs:{"src":_vm.img}})]),_c('div',{staticClass:"p-2",staticStyle:{"background-color":"#3a3a3a"}},[_c('div',{staticClass:"title-detail line-clamp-2"},[_vm._v(" "+_vm._s(_vm.detail.name)+" ")]),_c('b-row',[_c('b-col',{attrs:{"cols":"7"}},[(_vm.$store.state.theme.line_display_redeem_expire == '1')?_c('div',{staticClass:"valide-until"},[_vm._v(" แลกได้ถึง: "+_vm._s(_vm.dateFormat(_vm.detail.valid_to))+" ")]):_vm._e()]),_c('b-col',{attrs:{"cols":"5"}},[(_vm.detail.out_of_stock)?_c('div',{staticClass:"status-false"},[_vm._v(" ของรางวัลหมด ")]):_vm._e(),_c('div',{staticClass:"receive-point"},[_vm._v("ใช้ "+_vm._s(_vm.detail.point_used)+" คะแนน")])])],1)],1),_c('div',{staticClass:"panel-desc"},[(_vm.$store.state.theme.is_center_point == '1')?[_c('div',{staticClass:"d-inline-flex w-100"},[_c('div',[_vm._v("คะแนนสะสมปัจจุบัน")]),_c('div',{staticClass:"ml-auto"},[_vm._v(_vm._s(_vm._f("numeral")(_vm.totalPoint,"0,0"))+" คะแนน")])]),_c('div',{staticClass:"d-inline-flex w-100"},[_c('div',[_vm._v("จำนวนสินค้าที่แลก")]),_c('div',{staticClass:"ml-auto d-inline-flex align-items-center"},[_c('b-button',{attrs:{"variant":"custom-link","disabled":_vm.detail.redeemable === 'N' || _vm.redeemQty == 1},on:{"click":function($event){_vm.redeemQty -= 1}}},[_c('font-awesome-icon',{style:({ color: _vm.$store.state.theme.font_color }),attrs:{"icon":"minus-circle"}})],1),_c('div',{staticClass:"qty"},[_vm._v(_vm._s(_vm.redeemQty))]),_c('b-button',{attrs:{"variant":"custom-link","disabled":_vm.detail.redeemable === 'N' ||
                _vm.redeemQty >= _vm.detail.total_redeemable ||
                _vm.detail.point_used * (_vm.redeemQty + 1) > _vm.totalPoint},on:{"click":function($event){return _vm.addQty()}}},[_c('font-awesome-icon',{style:({ color: _vm.$store.state.theme.font_color }),attrs:{"icon":"plus-circle"}})],1),_c('br')],1)]),_c('div',{staticClass:"d-inline-flex w-100"},[_c('div',[_vm._v("จำนวนคะแนนที่ใช้")]),_c('div',{staticClass:"ml-auto"},[_vm._v(" "+_vm._s(_vm._f("numeral")((_vm.detail.point_used * _vm.redeemQty),"0,0"))+" คะแนน ")])]),_c('div',{staticClass:"d-inline-flex w-100"},[_c('div',[_vm._v("คะแนนคงเหลือ")]),_c('div',{staticClass:"ml-auto"},[_vm._v(" "+_vm._s(_vm._f("numeral")((_vm.totalPoint - _vm.detail.point_used * _vm.redeemQty),"0,0"))+" คะแนน ")])]),_c('div',{staticClass:"text-right"},[_c('small',{staticClass:"text-danger f-xs"},[_vm._v("คงเหลือ "+_vm._s(_vm.detail.total_redeemable)+" ชิ้น")])]),_c('hr')]:_vm._e(),_c('h6',[_vm._v("รายละเอียด")]),(_vm.detail.description)?_c('div',{staticClass:"px-2",domProps:{"innerHTML":_vm._s(_vm.detail.description)},on:{"click":_vm.handleClick}}):_c('div',{staticClass:"text-center mb-2"},[_vm._v("ไม่มีรายละเอียด")])],2)]),(
      !(this.$route.query.page == 'history' && this.$route.query.type == 1)
    )?_c('Footer',{attrs:{"isDisable":_vm.detail.redeemable === 'N' ||
      _vm.detail.out_of_stock ||
      (this.$route.query.page == 'history' && _vm.detail.is_redeem == 1),"text":_vm.textButton()},on:{"onClick":_vm.handleConfirmRedeem}}):_vm._e(),_c('ModalRedeem',{ref:"modalRedeem",attrs:{"detail":_vm.detail},on:{"confirmRedeem":_vm.handleConfirmRedeem}}),_c('ModalRedeemAdress',{ref:"modalRedeemAddress",attrs:{"desciptionMsg":_vm.desciptionMsg,"btnMsg":_vm.btnMsg,"titleMsg":_vm.titleMsg},on:{"submitModal":_vm.handleAddressFill}}),_c('ModalRedeemPoint',{ref:"modalRedeemPoint",attrs:{"detail":_vm.detail,"totalPoint":_vm.totalPoint + _vm.branchPoint,"address":_vm.addressList,"redeemQty":_vm.redeemQty},on:{"redeemSuccess":_vm.redeemSuccess,"confirm":_vm.handleModalPointClick,"showLoading":_vm.showLoading,"hideLoading":_vm.hideLoading,"redeemError":_vm.redeemError}}),_c('ModalSelectBranch',{ref:"ModalSelectBranch",attrs:{"branch":_vm.branch,"detail":_vm.detail,"redeemQty":_vm.redeemQty},on:{"success":_vm.success,"error":_vm.error,"showPointUsedModal":_vm.showPointUsedModal,"showLoading":_vm.showLoading,"hideLoading":_vm.hideLoading,"onBranchChange":_vm.onBranchChange}}),_c('ModalAlert',{ref:"modalAlert",attrs:{"text":_vm.msg},on:{"hide":_vm.refreshPage}}),_c('ModalError',{ref:"modalError",attrs:{"text":_vm.msg}}),_c('ModalLoading',{ref:"modalLoading"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }