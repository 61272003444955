import { render, staticRenderFns } from "./RewardDetailPanel.vue?vue&type=template&id=773baa89&scoped=true"
import script from "./RewardDetailPanel.vue?vue&type=script&lang=js"
export * from "./RewardDetailPanel.vue?vue&type=script&lang=js"
import style0 from "./RewardDetailPanel.vue?vue&type=style&index=0&id=773baa89&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "773baa89",
  null
  
)

export default component.exports