<template>
  <div v-if="isShow" id="loading"><Loading /></div>
</template>

<script>
import Loading from "./Loading.vue";
export default {
  components: { Loading },
  data() {
    return {
      isShow: false
    };
  },
  methods: {
    show() {
      this.isShow = true;
    },
    hide() {
      this.isShow = false;
    }
  }
};
</script>

<style>
#loading {
  height: 100vh;
  position: fixed;
  z-index: 1060;
  width: 100vw;
  left: 0;
  top: 0;
}
</style>
