<template>
  <div class="container-loading">
    <b-spinner
      label="Loading..."
      :variant="variant"
      class="m-5 spinner-main"
    ></b-spinner>
  </div>
</template>

<script>
export default {
  name: "loading",
  props: {
    variant: {
      required: false,
      type: String
    }
  }
};
</script>

<style lang="scss" scoped>
.container-loading {
  min-height: 100vh;
  width: 100%;
  background: rgba(128, 128, 128, 0.514);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  z-index: 9999;
}
</style>
